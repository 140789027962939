@media print {
  .kt-aside,
  .kt-subheader,
  #kt_header {
    display: none !important;
    z-index: 0;
  }
  .kt-wrapper {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
}

#kt_footer {
  display: none;
}
